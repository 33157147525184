 .App {
  text-align: center;

}

.Alert {
  text-align: center;
  color: red;
  font-size: 20px;
  font-weight: bold;
}

.Success {
  text-align: center;
  color: green;
  font-size: 20px;
  font-weight: bold;
}

.output_message_succes{
  text-align: center;
  color: green;
  font-size: 20px;
  font-weight: bold;
}